import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const InactivityLogout = ({ timeout = 600000 }) => { // Default timeout of 10 minutes
    const navigate = useNavigate();
    const timer = useRef(null);

    const resetTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            // Clear session storage or token
            localStorage.removeItem('authToken');
            navigate('/'); // Redirect to login
        }, timeout);
    };

    useEffect(() => {
        // Set up event listeners for user activity
        const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];
        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });

        resetTimer(); // Start the initial timer

        return () => {
            // Cleanup event listeners on component unmount
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
            if (timer.current) {
                clearTimeout(timer.current);
            }
        };
    }, [timeout]);

    return null; // This component doesn't render anything
};

export default InactivityLogout;
