// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../services/orderService';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const LoginPage = ({ setIsAuthenticated }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await loginUser({ username, password });
            console.log('Login successful:', response.data);
            localStorage.setItem('authToken', response.data.token);
            setIsAuthenticated(true); 
            navigate('/order/list');
        } catch (error) {
            console.error('Login failed:', error.response ? error.response.data : error.message);
            setError('Login failed. Please try again.');
        }
    };

    return (
        <div className='login-form-container'>
            <Form onSubmit={handleSubmit} autoComplete="off">
                <Form.Group>
                    <Form.Label htmlFor="Username">Username</Form.Label>
                    <Form.Control type="text" id="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </Form.Group>
                <Form.Group>
                    <Form.Label htmlFor="Password">Password</Form.Label>
                    <Form.Control type="password" id="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </Form.Group>
                <div className='login-btn-container'>
                    <Button type="submit" variant='primary' className='login-btn'>Login</Button>
                </div>
            </Form>
            {error && <p className='error'>{error}</p>}
        </div>
    );
};

export default LoginPage;
