import React, { forwardRef } from 'react';

const PrintableContent = forwardRef(({ order }, ref) => {
    return (
        <div ref={ref} className="printable-content">
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                            <strong>Испраќа:</strong> Габриела
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                            <strong>Контакт:</strong> 078415949
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                            <strong>Примач:</strong> {order.customerName}
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                            <strong>Контакт:</strong> {order.phoneNumber}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                            <strong>Адреса:</strong> {order.customerAdress}
                        </td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>
                            <strong>Град:</strong> {order.city}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2" style={{ border: '1px solid black', padding: '8px' }}>
                            <strong>Откуп:</strong> {order.price}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
});

export default PrintableContent;