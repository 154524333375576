// src/services/orderService.js
import axios from 'axios';

const API_BASE_URL = 'https://data.tinissima.react-plus.de/api'

//'http://localhost:5082/api'

//'https://data.tinissima.react-plus.de/api'



export const getOrderStatuses = () => {
    return axios.get(`${API_BASE_URL}/staticdata/order-statuses`);
};
export const getProductTypes = () => {
    return axios.get(`${API_BASE_URL}/staticdata/product-types`);
};

export const getOrders = async () => {

    const token = localStorage.getItem('authToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return await axios.get(`${API_BASE_URL}/orders`, config);
};

export const getOrdersByStatus = async (statusId) => {
    const token = localStorage.getItem('authToken');
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    return await axios.get(`${API_BASE_URL}/orders/byStatus?statusId=${statusId}`, config);
};

export const getOrder = (id) => {
    return axios.get(`${API_BASE_URL}/orders/${id}`);
};

export const createOrder = (order) => {
    return axios.post(`${API_BASE_URL}/orders/create`, order);
};

export const updateOrder = async (orderData) => {
    const url = `${API_BASE_URL}/orders/update`;
    try {
        const response = await axios.post(url, orderData);
        return response.data;
    } catch (error) {
        console.error('Failed to update order:', error.response ? error.response.data : error.message);
        throw new Error('Failed to update order');
    }
};
export const deleteOrder = (id) => {
    return axios.delete(`${API_BASE_URL}/orders/${id}`);
};

//UserAPI
export const addUser = (userData) => {
    return axios.post(`${API_BASE_URL}/user`, userData);
};

//LoginAPI
export const loginUser = async ({ username, password }) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/login`, { username, password });
        return response;
    } catch (error) {
        // Forward the error to be caught in the calling function
        throw error;
    }
};
//Reports 


export const OrderService = {
    // Fetch the order report data
    getOrderReport: async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/orders/report`);
            return response.data;
        } catch (error) {
            console.error('Error fetching order report:', error);
            throw error; // Re-throw the error to be handled by the calling component
        }
    }
}