import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import { getOrderStatuses, getProductTypes, createOrder } from '../services/orderService';

function OrderForm() {
    const [statuses, setStatuses] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const [orderData, setOrderData] = useState({
        OrderId: '',
        customerName: '',
        orderStatus: '',
        price: '',
        productType: '',
        description: '',
        CustomerAdress: '',
        city: '',
        phoneNumber: ''
    });
    const navigate = useNavigate();


    useEffect(() => {
        const fetchProductTypes = async () => {
            try {
                const response = await getProductTypes();
                setProductTypes(response.data);
                
            } catch (err) {
                console.error(err);
            }
        }
            
        const fetchStatuses = async () => {
            try {
                const response = await getOrderStatuses();
                setStatuses(response.data);
                
            } catch (err) {
                console.error(err);
            } 
        }

        fetchStatuses();
        fetchProductTypes();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrderData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formattedOrderData = {
            ...orderData,
            price: parseFloat(orderData.price) || 0.00  // Convert price to a numeric value with two decimal places
        };
    
        
        createOrder(formattedOrderData)
            .then(response => {
                console.log('Order submitted:', response.data);
                navigate('/order/list'); 
            })
            .catch(error => {
                // Log the full error response from the server for debugging
                console.error('Error submitting order:', error.response ? error.response.data : error.message);
            });

            console.log('Submitting order data:', formattedOrderData);
    };

    const handleBack = () => {
        navigate('/order/list');
    };

    
    return (
        <Form onSubmit={handleSubmit}>
            <Row className="justify-content-center align-items-center">
                <Col xs={12} md={8} lg={9}>
                    <Form.Group controlId="orderId">
                        <Form.Label>Број на нарачка</Form.Label>
                        <Form.Control type="text"  name="orderId"  onChange={handleChange} disabled />
                    </Form.Group>
                    <Form.Group controlId="customerName">
                        <Form.Label >Име</Form.Label>
                        <Form.Control type="text" name="customerName" value={orderData.customerName} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="CustomerAdress">
                        <Form.Label >Адреса</Form.Label>
                        <Form.Control type="text" name="CustomerAdress" value={orderData.CustomerAdress} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="city">
                        <Form.Label >Град</Form.Label>
                        <Form.Control type="text" name="city" value={orderData.city} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="phoneNumber">
                        <Form.Label >Телефон</Form.Label>
                        <Form.Control type="text" name="phoneNumber" value={orderData.phoneNumber} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group controlId="orderStatus">
                        <Form.Label>Статус на нарачка</Form.Label>
                        <Form.Select
                            id="orderStatus"
                            name="orderStatus"
                            value={orderData.orderStatus}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Избери</option>
                            {statuses.map(status => (
                                <option key={status.id} value={status.id}>{status.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Цена</Form.Label>
                        <Form.Control type="text" id="price" name="price" value={orderData.price} onChange={handleChange} required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Производ</Form.Label>
                        <Form.Select id="productType" name="productType" value={orderData.productType} onChange={handleChange} required>
                            <option value="">Избери производ</option>
                            {productTypes.map(type => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Опис</Form.Label>
                        <Form.Control as="textarea" rows={3} id="description" name="description" value={orderData.description} onChange={handleChange} required  />
                    </Form.Group>
                    <Button type="submit" variant='primary'style={{ marginRight: '10px' }}>Додади Нарачка</Button>
                    <Button type="button" variant='secondary' onClick={handleBack}>Назад</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default OrderForm;
