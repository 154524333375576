import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Row, Col, Table, Container, Navbar, Nav } from 'react-bootstrap';

import { getOrderStatuses, OrderService } from '../services/orderService'

const OrderReport = () => {
    const [report, setReport] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState({});

    useEffect(() => {
        
        const fetchOrderStatuses = async () => {
            try {
                const response = await getOrderStatuses();
                const orderStatusMap = response.data.reduce((map, type) => {
                    map[type.id] = type.name;
                    return map;
                }, {});
                setOrderStatuses(orderStatusMap);
                const data = await OrderService.getOrderReport();
                setReport(data);
            } catch (error) {
                console.error('Error fetching product types:', error);
            }
        };
        
        fetchOrderStatuses();
    }, []);
    console.log(report);
    return (
        <div>
            <Container  fluid>
                <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
                    <Nav>
                        <Nav.Link 
                        as={Link} 
                        to="/order/list"
                        className="text-primary fw-bold px-3 py-2 rounded"
                        style={{ backgroundColor: '#e9ecef' }}
                        >Листа на нарачаки</Nav.Link>
                    </Nav>
                   
                </Navbar>
             <Row>
                <Col xs={7}>
            <h2>Сумарен извештај за нарачки</h2>
            <Table striped bordered hover responsive="md" className='ciklama-table'>
                <thead>
                    <tr>
                        <th>Статус на нарачки</th>
                        <th>Вкупно нарачки</th>
                        <th>Вкупна цена</th>
                    </tr>
                </thead>
                <tbody>
                    {report.map((summary, index) => (
                        <tr key={index}>
                            <td>{orderStatuses[summary.orderStatus] || summary.orderStatus}</td>
                            <td>{summary.totalOrders}</td>
                            <td>{summary.priceTotal}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            </Col>
            </Row>
            </Container>
        </div>
    );
};

export default OrderReport;
