import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, Link  } from 'react-router-dom';
import axios from 'axios';
import { getOrders, getOrderStatuses, getProductTypes, getOrdersByStatus } from '../services/orderService'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Pagination from 'react-bootstrap/Pagination'
import { useReactToPrint } from 'react-to-print';
import PrintableContent from './PrintDoc';


const OrderList = () => {
    const [orders, setOrders] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [productTypes, setProductTypes] = useState({});
    const [orderStatuses, setOrderStatuses] = useState({});
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedOrderData, setSelectedOrderData] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
    const navigate = useNavigate();
    const componentRef = useRef();


    const fetchOrders = async () => {
        try {
            const response = await getOrders();
            setOrders(response.data);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    useEffect(() => {
         

        const fetchProductTypes = async () => {
            try {
                const response = await getProductTypes();
                const productTypeMap = response.data.reduce((map, type) => {
                    map[type.id] = type.name;
                    return map;
                }, {});
                setProductTypes(productTypeMap);
                
            } catch (err) {
                console.error(err);
            }
        }
        
        const fetchOrderStatuses = async () => {
            try {
                const response = await getOrderStatuses();
                const orderStatusMap = response.data.reduce((map, type) => {
                    map[type.id] = type.name;
                    return map;
                }, {});
                setOrderStatuses(orderStatusMap);
                setStatuses(response.data);
            } catch (error) {
                console.error('Error fetching product types:', error);
            }
        };

        fetchOrders();
        fetchProductTypes();
        fetchOrderStatuses();

    }, []);

    
    const handleAddOrder = () => {
        navigate('/order/new');
    };
    
    const handleAddUser = () => {
        navigate('/add-user');
    };

    const handleRowClick = (id) => {
        setSelectedOrderId(id);
        console.log(selectedOrderData);
    };

    useEffect(() => {
        if (selectedOrderId) {
            axios.get(`/api/orders/${selectedOrderId}`) // Replace with your actual endpoint
                .then(response => {
                    setSelectedOrderData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching order data:', error);
                });
        }
    }, [selectedOrderId]);

    const handleViewDetails = () => {
        if (selectedOrderId) {
            navigate(`/order/${selectedOrderId}`);
        }
    };

    const handleChange = async (e) => {
        const { value } = e.target;
        setSelectedOrderStatus(value);

        if (value) {
            try {
                const response = await getOrdersByStatus(value);
                setOrders(response.data); // Assuming response contains a list of orders
                console.log('Orders:', response.data); // Log the fetched orders
            } catch (error) {
                console.error('Error fetching orders by status:', error);
            }
        } else {
            setOrders([]); // Clear orders if no status is selected
        }
    };


    return (
        <div className='order-container'>
            <Row className="justify-content-center align-items-center">
                <Col xs={12} md={8} lg={9}>
                    <Container  fluid>
                    <Navbar sticky="top" expand="lg" className="bg-body-tertiary">
                        <Nav>
                            <Nav.Link
                                as={Link}
                                to="/report"
                                className="text-primary fw-bold px-3 py-2 rounded"
                                style={{ backgroundColor: '#e9ecef' }} // You can still add inline styles for specifics
                            >
                                Извештаи
                            </Nav.Link>
                        </Nav>
                    </Navbar>
                        <h1>Листа на нарачки</h1>
                        <Form.Group controlId="orderStatus">
                                <Form.Label>Статус на нарачка</Form.Label>
                                <Form.Select
                                    id="orderStatus"
                                    name="orderStatus"
                                    value={selectedOrderStatus}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Избери</option>
                                    {statuses.map(status => (
                                        <option key={status.id} value={status.id}>{status.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        {orders.length > 0 ? (                         
                            <Table striped bordered hover responsive="md" className='ciklama-table'>
                                <thead>
                                    <tr>
                                        <th className="col-select">Избери</th>
                                        <th className="col-order-id">Број на нарачка</th>
                                        <th className="col-customer-name">Име на купувач</th>
                                        <th className="col-product-type">Производ</th>
                                        <th className="col-price">Цена</th>
                                        <th className="col-description">Опис</th>
                                        <th className="col-status">Статус</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map((order) => (
                                        <tr
                                            key={order.id}
                                            onClick={() => handleRowClick(order.id)}
                                            style={{ cursor: 'pointer', backgroundColor: selectedOrderId === order.id ? '#e0e0e0' : 'white' }}
                                        >
                                            <td className="col-select">
                                                <input
                                                    type="radio"
                                                    checked={selectedOrderId === order.id}
                                                    readOnly
                                                />
                                            </td>
                                            <td className="col-order-id">{order.orderId}</td>
                                            <td className="col-customer-name">{order.customerName}</td>
                                            <td className="col-product-type">{productTypes[order.productType] || 'Unknown'}</td>
                                            <td className="col-price">{order.price}</td>
                                            <td className="col-description">{order.description}</td>
                                            <td className="col-status">{orderStatuses[order.orderStatus]}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                         ) : 
                        (
                            <p>
                                Нема внесени нарачки
                            </p>
                        )}
                        <div className="d-flex justify-content-between align-items-start">
                            <ButtonGroup vertical>
                                <Button onClick={handleAddOrder} variant='primary'>Додади нова нарачка</Button>
                                <Button onClick={handleViewDetails} disabled={!selectedOrderId} variant="warning">Детали за нарачка</Button>
                            </ButtonGroup>
                               
                                <Button onClick={handleAddUser} variant="warning" className='new-user-btn'>Додади Нов Корисник</Button>
                        </div>
                    </Container>
                </Col>
            </Row>
            
        </div>
    );
};

export default OrderList;
