// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OrderForm from './components/OrderForm';
import OrderList from './components/OrderList';
import OrderDetail from './components/OrderDetail';
import LoginPage from './components/LoginForm';
import UserForm from './components/UserForm';
import OrderReport from './components/OrderReport';
import PrivateRoute from './components/PrivateRoute';
import InactivityLogout from './components/InactovityLogout';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        setIsAuthenticated(!!token);
    }, []);

    return (
        <Router>
            <InactivityLogout timeout={600000} />
            <div>
                <Routes>
                    <Route path="/" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
                    <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
                        <Route path="/order/list" element={<OrderList />} />
                        <Route path="/order/new" element={<OrderForm />} />
                        <Route path="/order/:id" element={<OrderDetail />} />
                        <Route path="/add-user" element={<UserForm />} />
                        <Route path="/report" element={<OrderReport />} />
                    </Route>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
