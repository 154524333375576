import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { addUser } from '../services/orderService'

function UserForm() {
    const [userData, setUserData] = useState({
        username: '',
        password: ''
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        addUser(userData) 
        .then(response => {
            console.log('User added:', response.data);
            navigate('/order/list'); 
        })
        .catch(error => {
            console.error('Error adding user:', error);
        });
    };

    return (
        <form onSubmit={handleSubmit} autocomplete="off">
            <div>
                <label htmlFor="username">Username</label>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={userData.username}
                    onChange={handleChange}
                    required
                />
            </div>
            <div>
                <label htmlFor="password">Password</label>
                <input
                    type="password"
                    id="password"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    required
                />
            </div>
            <button type="submit">Add User</button>
            {message && <p>{message}</p>}
        </form>
    );
}

export default UserForm;
