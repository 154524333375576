import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { getOrderStatuses, getProductTypes, getOrder, updateOrder } from '../services/orderService';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import { useReactToPrint } from 'react-to-print';
import PrintableContent from './PrintDoc';

const OrderDetail = () => {
    const [statuses, setStatuses] = useState([]);
    const [productTypes, setProductTypes] = useState([]);
    const { id } = useParams(); // Get the order ID from the URL
    const navigate = useNavigate();
    const componentRef = useRef();
    const [orderData, setOrderData] = useState({
        orderId: '',
        customerName: '',
        orderStatus: '',
        price: '',
        productType: '',
        description: '',
        id: '',
        customerAdress: '',
        city: '',
        phoneNumber: ''
    });

    useEffect(() => {
        const fetchProductTypes = async () => {
            try {
                const response = await getProductTypes();
                setProductTypes(response.data);
                
            } catch (err) {
                console.error(err);
            }
        }
            
        const fetchStatuses = async () => {
            try {
                const response = await getOrderStatuses();
                setStatuses(response.data);
                
            } catch (err) {
                console.error(err);
            } 
        }

        fetchProductTypes();
        fetchStatuses();
    }, []);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await getOrder(id);
                setOrderData(response.data);
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrder(id);
        console.log('Loaded data', orderData);
    }, [id]);

   
    
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setOrderData(prevState => ({ ...prevState, [name]: value }));

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if orderData.id exists
        if (!orderData.id) {
            console.error('Order ID is missing or undefined:', orderData);
            
            return;
        }

        try {
            // Call updateOrder function
            const response = await updateOrder(orderData);
            navigate('/order/list'); 
            console.log('Order updated successfully:', response);
            // Optionally handle success, like redirecting or showing a message
        } catch (error) {
            console.error('Error updating the order:', error);
            // Optionally handle error, like showing an error message to the user
        }
    };
    
    const handleBack = () => {
        navigate('/order/list');
    };

    const handlePrint = useReactToPrint({
        
        content: () => componentRef.current,
        documentTitle: `Order_${orderData.orderId}_Details`,
        
    });

    return (

        <div className="form-container">
            <Form onSubmit={handleSubmit} >
                <Row className="justify-content-center align-items-center">
                    <Col xs={12} md={8} lg={9}>
                        <Form.Group controlId="orderId">
                            <Form.Label>Број на нарачка</Form.Label>
                            <Form.Control type="text"  name="orderId" value={orderData.orderId} onChange={handleChange} disabled />
                        </Form.Group>
                        <Form.Group controlId="customerName">
                            <Form.Label >Customer Name</Form.Label>
                            <Form.Control type="text" name="customerName" value={orderData.customerName} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group controlId="customerAdress">
                            <Form.Label >Адреса</Form.Label>
                            <Form.Control type="text" name="customerAdress" value={orderData.customerAdress} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group controlId="city">
                            <Form.Label >Град</Form.Label>
                            <Form.Control type="text" name="city" value={orderData.city} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group controlId="phoneNumber">
                            <Form.Label >Телефон</Form.Label>
                            <Form.Control type="text" name="phoneNumber" value={orderData.phoneNumber} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group controlId="orderStatus">
                            <Form.Label>Статус на нарачка</Form.Label>
                            <Form.Select
                                id="orderStatus"
                                name="orderStatus"
                                value={orderData.orderStatus}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Избери</option>
                                {statuses.map(status => (
                                    <option key={status.id} value={status.id}>{status.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label >Цена</Form.Label>
                            <Form.Control type="text" id="price" name="price" value={orderData.price} onChange={handleChange} required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label >Производ</Form.Label>
                            <Form.Select id="productType" name="productType" value={orderData.productType} onChange={handleChange} required>
                                <option value="">Select Product Type</option>
                                {productTypes.map(type => (
                                    <option key={type.id} value={type.id}>{type.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Опис</Form.Label>
                            <Form.Control as="textarea" rows={3} id="description" name="description" value={orderData.description} onChange={handleChange} required  />
                            
                        </Form.Group>
                        <Button type="submit"  className="save-btn">Зачувај</Button>
                        <Button type="button"  className="cancel-btn" onClick={handleBack}>Cancel</Button    ></Col>
                        <Button onClick={handlePrint} variant="warning">Испратница</Button>
                    </Row>
                </Form>
                {orderData && (
                <div style={{ display: 'none' }}>
                    <PrintableContent ref={componentRef} order={orderData} />
                </div>
            )}
            </div>
    );
};

export default OrderDetail;
